<template>
    <div>
        <p v-if="config.label">{{ config.label }}</p>
        <v-btn color="primary" @click="config.add()">
            <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-data-table :headers="config.headers"
                      :items="model[config.key]"
                      :items-per-page="10"
                      :footer-props="{'items-per-page-options': [10, 50, 100]}"
                      item-key="id">
            <template v-slot:item.action="{ item }">
                <delete-button @delete="config.delete(item)" name="company" text="Elimina"></delete-button>
            </template>
        </v-data-table>
    </div>
</template>

<script>

import DeleteButton from "./DeleteButton";

export default {
    components: {DeleteButton},
    name: 'FormDatagrid',
    props: ['config', 'model'],
}
</script>
